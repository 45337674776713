import { deleteCookie, setCookie } from 'cookies-next';
import Router from 'next/router';
import { ROUTES } from 'src/config';
import useFootprintStore from 'src/hooks/store/useFootprintStore';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import useMeasureStore from './useMeasureStore';
import { RouteIntentsEnum } from 'src/__apolloGenerated__/graphql';

export type AuthStoreType = {
  authState: {
    isAuthenticated: boolean;
    isExchangingMfaForJwt: boolean;
    emailVerified: boolean;
    accessToken: string | null;
    loginToken: string | null;
    userId: string | null;
    routeIntent?: RouteIntentsEnum | null;
  };
  setAuthState: (newState: AuthStoreType['authState']) => void;
  clear: () => void;
  isSigningOut: boolean;
  setIsSigningOut: (newState: boolean) => void;
  signout: (reroute?: boolean) => void;
  authenticate: (token: string, user, reroute?: boolean) => void;
  verifyEmail: (token: string, user, reroute?: boolean) => void;
  setRouteIntent: (routeIntent: RouteIntentsEnum) => void;
  clearRouteIntent: () => void;
};

const initAuthState: AuthStoreType['authState'] = {
  isAuthenticated: false,
  isExchangingMfaForJwt: false,
  emailVerified: false,
  accessToken: null,
  loginToken: null,
  userId: null
};
export const getAuthState = () => {
  return typeof window == 'undefined'
    ? initAuthState
    : localStorage.getItem('auth-store')
      ? JSON.parse(localStorage.getItem('auth-store')).state
      : initAuthState;
};

// localStorage.removeItem('auth-store');
const clearState = () => useAuthPersistStore.persist.clearStorage();

const useAuthPersistStore = create<AuthStoreType>()(
  persist(
    immer<AuthStoreType>((set, get) => ({
      authState: getAuthState(),
      clear: () => clearState(), // only clears the LocalStorage in the browser but not the zustand state object
      setAuthState: (newState) => set({ authState: newState }),
      isSigningOut: false,
      setIsSigningOut: (newState) => {
        set((store) => {
          store.isSigningOut = newState;
        });
      },
      signout: async (reroute = true) => {
        get().setIsSigningOut(true);
        get().setAuthState(initAuthState);
        // get().clear();
        deleteCookie('token');
        useFootprintStore.getState().setSelectedTree(null);
        const measureStore = useMeasureStore.getState();
        measureStore.setSelectedRows({
          branches: {},
          measurements: {}
        });
        measureStore.setActiveNode({
          nodeId: null,
          type: null,
          path: null
        });
        if (reroute) {
          Router.push(ROUTES.ROOT);
        }
      },
      authenticate: async (token, user, reroute = true) => {
        if (token) {
          if (reroute) {
            Router.push(ROUTES.ROADMAP.LIST);
          }
          setCookie('token', token);
          set((store) => {
            store.authState.isAuthenticated = true;
            store.authState.isExchangingMfaForJwt = false;
            store.authState.emailVerified = user.emailVerified;
            store.authState.accessToken = token;
            store.authState.userId = user.identifier;
          });
        }
      },
      verifyEmail: async (loginToken, user, reroute = true) => {
        if (loginToken) {
          if (reroute) {
            Router.push(ROUTES.AUTH.VERIFY);
          }
          set((store) => {
            // store.authState.isAuthenticated = false;
            store.authState.isExchangingMfaForJwt = true;
            store.authState.emailVerified = user.emailVerified;
            store.authState.loginToken = loginToken;
          });
        } else
          throw new Error(
            `Verification token is not provided: loginToken=${loginToken}; user=${user}`
          );
      },
      setRouteIntent: (routeIntent) => {
        set((store) => {
          store.authState.routeIntent = routeIntent;
        });
      },
      clearRouteIntent: () => {
        set((store) => {
          store.authState.routeIntent = null;
        });
      }
    })),
    {
      name: 'auth-store', // name of the item in the storage (must be unique)
      partialize: (store: AuthStoreType) => store.authState
      //   storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useAuthPersistStore;
