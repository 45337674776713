import {
  EmissionFactorFragmentFragment,
  EnergyUnitsEnum,
  GhgProtocolsEnum,
  RefrigerantTypesEnum,
  UnitTypesEnum,
  UnitsEnum,
  VolumeUnitsEnum,
  WeightUnitsEnum
} from 'src/__apolloGenerated__/graphql';
import { fEnum } from 'src/utils/format';

export const SpendUnitsEnum = ['CAD'];
// export const WeightUnitsEnum = [
//   'KG',
//   'OZ',
//   'G',
//   'GR',
//   'TON',
//   'TONNE', 
//   'LBS'
// ];
export const DistanceUnitsEnum = ['KM', 'MI'];
// export const VolumeUnitsEnum = [
//   'M3',
//   'YD3',
//   'CY',
//   'L',
//   'CM3',
//   'ML',
//   'AL',
//   'IMP_GAL',
//   'US_GAL'
// ];
export const AreaUnitsEnum = ['M2', 'FTM', 'FT2', 'CM2', 'MM2'];
export const LengthUnitsEnum = ['M', 'FT', 'IN', 'CM', 'MM', 'MIL'];
export const PowerUnitsEnum = ['KW', 'TW', 'W', 'AKV'];
export const ItemUnitsEnum = ['ITEM', 'PIECE', 'UNIT', 'EA', 'BAG'];
// export const EnergyUnitsEnum = ['KWH', 'GJ'];
export const TimeUnitsEnum = [
  'MS',
  'S',
  'MIN',
  'H',
  'D',
  'WEEK',
  'MON',
  'Y'
];
export const WeightOverTimeUnitsEnum = [
  'KG_MS',
  'KG_S',
  'KG_MIN',
  'KG_H',
  'KG_D',
  'KG_WEEK',
  'KG_MON',
  'KG_Y',
  'OZ_MS',
  'OZ_S',
  'OZ_MIN',
  'OZ_H',
  'OZ_D',
  'OZ_WEEK',
  'OZ_MON',
  'OZ_Y',
  'G_MS',
  'G_S',
  'G_MIN',
  'G_H',
  'G_D',
  'G_WEEK',
  'G_MON',
  'G_Y',
  'GR_MS',
  'GR_S',
  'GR_MIN',
  'GR_H',
  'GR_D',
  'GR_WEEK',
  'GR_MON',
  'GR_Y',
  'TON_MS',
  'TON_S',
  'TON_MIN',
  'TON_H',
  'TON_D',
  'TON_WEEK',
  'TON_MON',
  'TON_Y',
  'TONNE_MS',
  'TONNE_S',
  'TONNE_MIN',
  'TONNE_H',
  'TONNE_D',
  'TONNE_WEEK',
  'TONNE_MON',
  'TONNE_Y',
  'LBS_MS',
  'LBS_S',
  'LBS_MIN',
  'LBS_H',
  'LBS_D',
  'LBS_WEEK',
  'LBS_MON',
  'LBS_Y'
];
export const AreaOverTimeUnitsEnum = [
  'M2_MS',
  'M2_S',
  'M2_MIN',
  'M2_H',
  'M2_D',
  'M2_WEEK',
  'M2_MON',
  'M2_Y',
  'FTM_MS',
  'FTM_S',
  'FTM_MIN',
  'FTM_H',
  'FTM_D',
  'FTM_WEEK',
  'FTM_MON',
  'FTM_Y',
  'FT2_MS',
  'FT2_S',
  'FT2_MIN',
  'FT2_H',
  'FT2_D',
  'FT2_WEEK',
  'FT2_MON',
  'FT2_Y',
  'CM2_MS',
  'CM2_S',
  'CM2_MIN',
  'CM2_H',
  'CM2_D',
  'CM2_WEEK',
  'CM2_MON',
  'CM2_Y',
  'MM2_MS',
  'MM2_S',
  'MM2_MIN',
  'MM2_H',
  'MM2_D',
  'MM2_WEEK',
  'MM2_MON',
  'MM2_Y'
];
export const DistanceOverTimeUnitsEnum = [
  'KM_MS',
  'KM_S',
  'KM_MIN',
  'KM_H',
  'KM_D',
  'KM_WEEK',
  'KM_MON',
  'KM_Y',
  'MI_MS',
  'MI_S',
  'MI_MIN',
  'MI_H',
  'MI_D',
  'MI_WEEK',
  'MI_MON',
  'MI_Y'
];
export const CpuOverTimeUnitsEnum = [
  'CPU_MS',
  'CPU_S',
  'CPU_MIN',
  'CPU_H',
  'CPU_D',
  'CPU_WEEK',
  'CPU_MON',
  'CPU_Y'
];
export const InstanceOverTimeUnitsEnum = [
  'INST_MS',
  'INST_S',
  'INST_MIN',
  'INST_H',
  'INST_D',
  'INST_WEEK',
  'INST_MON',
  'INST_Y'
];
export const ContainerOverDistanceUnitsEnum = ['TEU_KM', 'TEU_MI'];
export const PassengerOverDistanceUnitsEnum = ['P_KM', 'P_MI'];

export const WeightOverDistanceUnitsEnum = [
  'KG_KM',
  'KG_MI',
  'OZ_KM',
  'OZ_MI',
  'G_KM',
  'G_MI',
  'GR_KM',
  'GR_MI',
  'TON_KM',
  'TON_MI',
  'TONNE_KM',
  'TONNE_MI',
  'LBS_KM',
  'LBS_MI'
];
export const DataOverTimeUnitsEnum = [
  'MB_MS',
  'MB_S',
  'MB_MIN',
  'MB_H',
  'MB_D',
  'MB_WEEK',
  'MB_MON',
  'MB_Y',
  'GB_MS',
  'GB_S',
  'GB_MIN',
  'GB_H',
  'GB_D',
  'GB_WEEK',
  'GB_MON',
  'GB_Y',
  'TB_MS',
  'TB_S',
  'TB_MIN',
  'TB_H',
  'TB_D',
  'TB_WEEK',
  'TB_MON',
  'TB_Y'
];

export const UnitLabels = {
  [UnitsEnum.Cad]: 'CAD',
  [UnitsEnum.Kg]: 'kg',
  [UnitsEnum.Oz]: 'oz',
  [UnitsEnum.G]: 'g',
  [UnitsEnum.Gr]: 'gr',
  [UnitsEnum.Ton]: 'ton',
  [UnitsEnum.Tonne]: 'tonne',
  [UnitsEnum.Lbs]: 'lbs',
  [UnitsEnum.Km]: 'km',
  [UnitsEnum.Mi]: 'mi',
  [UnitsEnum.M3]: 'm³',
  [UnitsEnum.Yd3]: 'yd³',
  [UnitsEnum.Cy]: 'cy',
  [UnitsEnum.L]: 'L',
  [UnitsEnum.Cm3]: 'cm³',
  [UnitsEnum.Ml]: 'ml',
  [UnitsEnum.Al]: 'al',
  [UnitsEnum.M2]: 'm²',
  [UnitsEnum.Ftm]: 'ftm',
  [UnitsEnum.Ft2]: 'ft²',
  [UnitsEnum.Cm2]: 'cm²',
  [UnitsEnum.Mm2]: 'mm²',
  [UnitsEnum.M]: 'm',
  [UnitsEnum.Ft]: 'ft',
  [UnitsEnum.In]: 'in',
  [UnitsEnum.Cm]: 'cm',
  [UnitsEnum.Mm]: 'mm',
  [UnitsEnum.Mil]: 'mil',
  [UnitsEnum.Kw]: 'kW',
  [UnitsEnum.Tw]: 'TW',
  [UnitsEnum.W]: 'W',
  [UnitsEnum.Akv]: 'aKV',
  [UnitsEnum.Item]: 'item',
  [UnitsEnum.Piece]: 'piece',
  [UnitsEnum.Unit]: 'unit',
  [UnitsEnum.Ea]: 'ea',
  [UnitsEnum.Bag]: 'bag',
  [UnitsEnum.Kwh]: 'kWh',
  [UnitsEnum.Gj]: 'GJ',
  [UnitsEnum.Ms]: 'ms',
  [UnitsEnum.S]: 's',
  [UnitsEnum.Min]: 'min',
  [UnitsEnum.H]: 'h',
  [UnitsEnum.D]: 'd',
  [UnitsEnum.Week]: 'week',
  [UnitsEnum.Mon]: 'mon',
  [UnitsEnum.Y]: 'y',
  [UnitsEnum.KgMs]: 'kg/ms',
  [UnitsEnum.KgS]: 'kg/s',
  [UnitsEnum.KgMin]: 'kg/min',
  [UnitsEnum.KgH]: 'kg/h',
  [UnitsEnum.KgD]: 'kg/d',
  [UnitsEnum.KgWeek]: 'kg/week',
  [UnitsEnum.KgMon]: 'kg/mon',
  [UnitsEnum.KgY]: 'kg/y',
  [UnitsEnum.OzMs]: 'oz/ms',
  [UnitsEnum.OzS]: 'oz/s',
  [UnitsEnum.OzMin]: 'oz/min',
  [UnitsEnum.OzH]: 'oz/h',
  [UnitsEnum.OzD]: 'oz/d',
  [UnitsEnum.OzWeek]: 'oz/week',
  [UnitsEnum.OzMon]: 'oz/mon',
  [UnitsEnum.OzY]: 'oz/y',
  [UnitsEnum.GMs]: 'g/ms',
  [UnitsEnum.GS]: 'g/s',
  [UnitsEnum.GMin]: 'g/min',
  [UnitsEnum.GH]: 'g/h',
  [UnitsEnum.GD]: 'g/d',
  [UnitsEnum.GWeek]: 'g/week',
  [UnitsEnum.GMon]: 'g/mon',
  [UnitsEnum.GY]: 'g/y',
  [UnitsEnum.GrMs]: 'gr/ms',
  [UnitsEnum.GrS]: 'gr/s',
  [UnitsEnum.GrMin]: 'gr/min',
  [UnitsEnum.GrH]: 'gr/h',
  [UnitsEnum.GrD]: 'gr/d',
  [UnitsEnum.GrWeek]: 'gr/week',
  [UnitsEnum.GrMon]: 'gr/mon',
  [UnitsEnum.GrY]: 'gr/y',
  [UnitsEnum.TonMs]: 'ton/ms',
  [UnitsEnum.TonS]: 'ton/s',
  [UnitsEnum.TonMin]: 'ton/min',
  [UnitsEnum.TonH]: 'ton/h',
  [UnitsEnum.TonD]: 'ton/d',
  [UnitsEnum.TonWeek]: 'ton/week',
  [UnitsEnum.TonMon]: 'ton/mon',
  [UnitsEnum.TonY]: 'ton/y',
  [UnitsEnum.TonneMs]: 'tonne/ms',
  [UnitsEnum.TonneS]: 'tonne/s',
  [UnitsEnum.TonneMin]: 'tonne/min',
  [UnitsEnum.TonneH]: 'tonne/h',
  [UnitsEnum.TonneD]: 'tonne/d',
  [UnitsEnum.TonneWeek]: 'tonne/week',
  [UnitsEnum.TonneMon]: 'tonne/mon',
  [UnitsEnum.TonneY]: 'tonne/y',
  [UnitsEnum.LbsMs]: 'lbs/ms',
  [UnitsEnum.LbsS]: 'lbs/s',
  [UnitsEnum.LbsMin]: 'lbs/min',
  [UnitsEnum.LbsH]: 'lbs/h',
  [UnitsEnum.LbsD]: 'lbs/d',
  [UnitsEnum.LbsWeek]: 'lbs/week',
  [UnitsEnum.LbsMon]: 'lbs/mon',
  [UnitsEnum.LbsY]: 'lbs/y',
  [UnitsEnum.M2Ms]: 'm²/ms',
  [UnitsEnum.M2S]: 'm²/s',
  [UnitsEnum.M2Min]: 'm²/min',
  [UnitsEnum.M2H]: 'm²/h',
  [UnitsEnum.M2D]: 'm²/d',
  [UnitsEnum.M2Week]: 'm²/week',
  [UnitsEnum.M2Mon]: 'm²/mon',
  [UnitsEnum.M2Y]: 'm²/y',
  [UnitsEnum.FtmMs]: 'ftm/ms',
  [UnitsEnum.FtmS]: 'ftm/s',
  [UnitsEnum.FtmMin]: 'ftm/min',
  [UnitsEnum.FtmH]: 'ftm/h',
  [UnitsEnum.FtmD]: 'ftm/d',
  [UnitsEnum.FtmWeek]: 'ftm/week',
  [UnitsEnum.FtmMon]: 'ftm/mon',
  [UnitsEnum.FtmY]: 'ftm/y',
  [UnitsEnum.Ft2Ms]: 'ft²/ms',
  [UnitsEnum.Ft2S]: 'ft²/s',
  [UnitsEnum.Ft2Min]: 'ft²/min',
  [UnitsEnum.Ft2H]: 'ft²/h',
  [UnitsEnum.Ft2D]: 'ft²/d',
  [UnitsEnum.Ft2Week]: 'ft²/week',
  [UnitsEnum.Ft2Mon]: 'ft²/mon',
  [UnitsEnum.Ft2Y]: 'ft²/y',
  [UnitsEnum.Cm2Ms]: 'cm²/ms',
  [UnitsEnum.Cm2S]: 'cm²/s',
  [UnitsEnum.Cm2Min]: 'cm²/min',
  [UnitsEnum.Cm2H]: 'cm²/h',
  [UnitsEnum.Cm2D]: 'cm²/d',
  [UnitsEnum.Cm2Week]: 'cm²/week',
  [UnitsEnum.Cm2Mon]: 'cm²/mon',
  [UnitsEnum.Cm2Y]: 'cm²/y',
  [UnitsEnum.Mm2Ms]: 'mm²/ms',
  [UnitsEnum.Mm2S]: 'mm²/s',
  [UnitsEnum.Mm2Min]: 'mm²/min',
  [UnitsEnum.Mm2H]: 'mm²/h',
  [UnitsEnum.Mm2D]: 'mm²/d',
  [UnitsEnum.Mm2Week]: 'mm²/week',
  [UnitsEnum.Mm2Mon]: 'mm²/mon',
  [UnitsEnum.Mm2Y]: 'mm²/y',
  [UnitsEnum.KmMs]: 'km/ms',
  [UnitsEnum.KmS]: 'km/s',
  [UnitsEnum.KmMin]: 'km/min',
  [UnitsEnum.KmH]: 'km/h',
  [UnitsEnum.KmD]: 'km/d',
  [UnitsEnum.KmWeek]: 'km/week',
  [UnitsEnum.KmMon]: 'km/mon',
  [UnitsEnum.KmY]: 'km/y',
  [UnitsEnum.MiMs]: 'mi/ms',
  [UnitsEnum.MiS]: 'mi/s',
  [UnitsEnum.MiMin]: 'mi/min',
  [UnitsEnum.MiH]: 'mi/h',
  [UnitsEnum.MiD]: 'mi/d',
  [UnitsEnum.MiWeek]: 'mi/week',
  [UnitsEnum.MiMon]: 'mi/mon',
  [UnitsEnum.MiY]: 'mi/y',
  [UnitsEnum.CpuMs]: 'cpu/ms',
  [UnitsEnum.CpuS]: 'cpu/s',
  [UnitsEnum.CpuMin]: 'cpu/min',
  [UnitsEnum.CpuH]: 'cpu/h',
  [UnitsEnum.CpuD]: 'cpu/d',
  [UnitsEnum.CpuWeek]: 'cpu/week',
  [UnitsEnum.CpuMon]: 'cpu/mon',
  [UnitsEnum.CpuY]: 'cpu/y',
  [UnitsEnum.InstMs]: 'inst/ms',
  [UnitsEnum.InstS]: 'inst/s',
  [UnitsEnum.InstMin]: 'inst/min',
  [UnitsEnum.InstH]: 'inst/h',
  [UnitsEnum.InstD]: 'inst/d',
  [UnitsEnum.InstWeek]: 'inst/week',
  [UnitsEnum.InstMon]: 'inst/mon',
  [UnitsEnum.InstY]: 'inst/y',
  [UnitsEnum.TeuKm]: 'TEU/km',
  [UnitsEnum.TeuMi]: 'TEU/mi',
  [UnitsEnum.PKm]: 'p/km',
  [UnitsEnum.PMi]: 'p/mi',
  [UnitsEnum.KgKm]: 'kg/km',
  [UnitsEnum.KgMi]: 'kg/mi',
  [UnitsEnum.OzKm]: 'oz/km',
  [UnitsEnum.OzMi]: 'oz/mi',
  [UnitsEnum.GKm]: 'g/km',
  [UnitsEnum.GMi]: 'g/mi',
  [UnitsEnum.GrKm]: 'gr/km',
  [UnitsEnum.GrMi]: 'gr/mi',
  [UnitsEnum.TonKm]: 'ton/km',
  [UnitsEnum.TonMi]: 'ton/mi',
  [UnitsEnum.TonneKm]: 'tonne/km',
  [UnitsEnum.TonneMi]: 'tonne/mi',
  [UnitsEnum.LbsKm]: 'lbs/km',
  [UnitsEnum.LbsMi]: 'lbs/mi',
  [UnitsEnum.MbMs]: 'MB/ms',
  [UnitsEnum.MbS]: 'MB/s',
  [UnitsEnum.MbMin]: 'MB/min',
  [UnitsEnum.MbH]: 'MB/h',
  [UnitsEnum.MbD]: 'MB/d',
  [UnitsEnum.MbWeek]: 'MB/week',
  [UnitsEnum.MbMon]: 'MB/mon',
  [UnitsEnum.MbY]: 'MB/y',
  [UnitsEnum.GbMs]: 'GB/ms',
  [UnitsEnum.GbS]: 'GB/s',
  [UnitsEnum.GbMin]: 'GB/min',
  [UnitsEnum.GbH]: 'GB/h',
  [UnitsEnum.GbD]: 'GB/d',
  [UnitsEnum.GbWeek]: 'GB/week',
  [UnitsEnum.GbMon]: 'GB/mon',
  [UnitsEnum.GbY]: 'GB/y',
  [UnitsEnum.TbMs]: 'TB/ms',
  [UnitsEnum.TbS]: 'TB/s',
  [UnitsEnum.TbMin]: 'TB/min',
  [UnitsEnum.TbH]: 'TB/h',
  [UnitsEnum.TbD]: 'TB/d',
  [UnitsEnum.TbWeek]: 'TB/week',
  [UnitsEnum.TbMon]: 'TB/mon',
  [UnitsEnum.TbY]: 'TB/y',
  [EnergyUnitsEnum.Mwh]: 'MWh - megawatt hours',
  [EnergyUnitsEnum.Mj]: 'MJ - megajoules',
  [EnergyUnitsEnum.Kj]: 'KJ - kilojoules',
  [EnergyUnitsEnum.Therm]: 'Therm - thermal unit',
  [EnergyUnitsEnum.Btu]: 'Btu - British thermal unit',
  [EnergyUnitsEnum.Cal]: 'cal - calorie',
  [EnergyUnitsEnum.Wh]: 'Wh - watt hour',
  [RefrigerantTypesEnum.R404A]: 'R404A - HFC',
  [RefrigerantTypesEnum.R407A]: 'R407A - HFC',
  [RefrigerantTypesEnum.R407B]: 'R407B - HFC',
  [RefrigerantTypesEnum.R407C]: 'R407C - HFC',
  [RefrigerantTypesEnum.R407D]: 'R407D - HFC',
  [RefrigerantTypesEnum.R410A]: 'R410A - HFC',
  [RefrigerantTypesEnum.R410B]: 'R410B - HFC',
  [RefrigerantTypesEnum.R507A]: 'R507A - HFC',
  [RefrigerantTypesEnum.R508A]: 'R508A - HFC',
  [RefrigerantTypesEnum.R508B]: 'R508B - HFC',
  [VolumeUnitsEnum.UsGal]: 'US gal - US gallons',
  [VolumeUnitsEnum.ImpGal]: 'Imp gal - Imperial gallons'
};

export const UnitTypesAndUnits: { [key: string]: string[] } = {
  [UnitTypesEnum.Spend]: SpendUnitsEnum,
  [UnitTypesEnum.Weight]: Object.values(WeightUnitsEnum),
  [UnitTypesEnum.Volume]: Object.values(VolumeUnitsEnum),
  [UnitTypesEnum.Area]: AreaUnitsEnum,
  [UnitTypesEnum.Length]: LengthUnitsEnum,
  [UnitTypesEnum.Power]: PowerUnitsEnum,
  [UnitTypesEnum.Item]: ItemUnitsEnum,
  [UnitTypesEnum.Energy]: Object.values(EnergyUnitsEnum),
  [UnitTypesEnum.Time]: TimeUnitsEnum,
  [UnitTypesEnum.WeightOverTime]: WeightOverTimeUnitsEnum,
  [UnitTypesEnum.AreaOverTime]: AreaOverTimeUnitsEnum,
  [UnitTypesEnum.DistanceOverTime]: DistanceOverTimeUnitsEnum,
  [UnitTypesEnum.CpuOverTime]: CpuOverTimeUnitsEnum,
  [UnitTypesEnum.InstanceOverTime]: InstanceOverTimeUnitsEnum,
  [UnitTypesEnum.ContainerOverDistance]:
    ContainerOverDistanceUnitsEnum,
  [UnitTypesEnum.PassengerOverDistance]:
    PassengerOverDistanceUnitsEnum,
  [UnitTypesEnum.WeightOverDistance]: WeightOverDistanceUnitsEnum,
  [UnitTypesEnum.DataOverTime]: DataOverTimeUnitsEnum
};

export const getUnitTypeFromUnit: (
  unit: UnitsEnum
) => UnitTypesEnum = (unit) => {
  for (const [unitType, units] of Object.entries(UnitTypesAndUnits)) {
    if (units.includes(unit)) {
      return unitType as UnitTypesEnum;
    }
  }
  return null;
};

export const DECIMAL_SCOPE_TO_GHG_PROTOCOL_MAP = {
  '0': 'Unknown',
  '1.0': 'Other',
  '1.1': 'CompanyFacilities',
  '1.2': 'CompanyVehicles',
  '2.0': 'Other',
  '2.1': 'PurchasedElectricity',
  '2.2': 'PurchasedSteam',
  '2.3': 'PurchasedHeat',
  '2.4': 'PurchasedCooling',
  '3.0': 'Other',
  '3.1': 'PurchasedGoodsAndServices',
  '3.2': 'CapitalGoods',
  '3.3': 'FuelAndEnergyRelatedActivities',
  '3.4': 'UpstreamTransportationAndDistribution',
  '3.5': 'WasteGeneratedInOperations',
  '3.6': 'BusinessTravel',
  '3.7': 'EmployeeCommuting',
  '3.8': 'UpstreamLeasedAssets',
  '3.9': 'DownstreamTransportationAndDistribution',
  '3.10': 'ProcessingOfSoldProducts',
  '3.11': 'UseOfSoldProducts',
  '3.12': 'EndOfLifeTreatmentOfSoldProducts',
  '3.13': 'DownstreamLeasedAssets',
  '3.14': 'Franchises',
  '3.15': 'Investments'
};

export const INTEGER_SCOPE_TO_GHG_PROTOCOLS_MAP = {
  '0': ['Other'],
  '1': ['CompanyFacilities', 'CompanyVehicles'],
  '2': [
    'PurchasedElectricity',
    'PurchasedSteam',
    'PurchasedHeat',
    'PurchasedCooling'
  ],
  '3': [
    'PurchasedGoodsAndServices',
    'CapitalGoods',
    'FuelAndEnergyRelatedActivities',
    'UpstreamTransportationAndDistribution',
    'WasteGeneratedInOperations',
    'BusinessTravel',
    'EmployeeCommuting',
    'UpstreamLeasedAssets',
    'DownstreamTransportationAndDistribution',
    'ProcessingOfSoldProducts',
    'UseOfSoldProducts',
    'EndOfLifeTreatmentOfSoldProducts',
    'DownstreamLeasedAssets',
    'Franchises',
    'Investments'
  ]
};

export const getDecimalScopeFromGhgProtocol = (
  ghgProtocol: GhgProtocolsEnum
) => {
  return Object.keys(DECIMAL_SCOPE_TO_GHG_PROTOCOL_MAP).find(
    (key) =>
      DECIMAL_SCOPE_TO_GHG_PROTOCOL_MAP[key] ===
      Object.keys(GhgProtocolsEnum).find(
        (key) => GhgProtocolsEnum[key] === ghgProtocol
      )
  );
};

export const getIntegerScopeFromGhgProtocol = (
  ghgProtocol: GhgProtocolsEnum
) => {
  return Object.keys(INTEGER_SCOPE_TO_GHG_PROTOCOLS_MAP).find((key) =>
    INTEGER_SCOPE_TO_GHG_PROTOCOLS_MAP[key].includes(ghgProtocol)
  );
};

export const getUnitOptionsFromEmissionFactor = (
  emissionFactor: EmissionFactorFragmentFragment
) => {
  // Filter units by emission factor unit type if there is an emission factor
  const emissionFactorUnit = emissionFactor?.unitInfo?.unit;
  const emissionFactorUnitType = getUnitTypeFromUnit(
    emissionFactorUnit
  );
  const unitsByEmissionFactor =
    UnitTypesAndUnits[emissionFactorUnitType];
  const unitOptions = unitsByEmissionFactor
    ? unitsByEmissionFactor.map((unit) => {
        return {
          key: unit,
          label: unit
        };
      })
    : Object.keys(UnitsEnum).map((unit) => {
        return {
          key: UnitsEnum[unit],
          label: unit
        };
      });

  return unitOptions;
};

export const getEmissionFactorUnitType = (
  emissionFactor: EmissionFactorFragmentFragment
) => {
  // Filter units by emission factor unit type if there is an emission factor
  const emissionFactorUnit = emissionFactor?.unitInfo?.unit;
  const emissionFactorUnitType = getUnitTypeFromUnit(
    emissionFactorUnit
  );
  return emissionFactorUnitType;
};

export const getGhgCategoryOptionsFromScope = (
  integerScope: string
) => {
  // Filter ghg protocols by emission factor scope
  const ghgProtocols =
    INTEGER_SCOPE_TO_GHG_PROTOCOLS_MAP[integerScope];
  const ghgProtocolOptions = ghgProtocols?.map(
    (key: keyof GhgProtocolsEnum) => {
      return {
        key: GhgProtocolsEnum[key],
        label: fEnum(GhgProtocolsEnum[key])
      };
    }
  );
  return ghgProtocolOptions;
};
