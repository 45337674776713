import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
// import { createUploadLink } from 'apollo-upload-client'; // A NEW SOLUTION
import { createUploadLink } from 'apollo-upload-client';
import { FRAGMENTS } from 'src/backend/fragments';
import { URLS } from 'src/config';
import { getAuthState } from './store/useAuthPersistStore';

// ----------------------------------------------------------------------

export default function useApollo() {
  /* --- a new APOLLO-UPLOAD-CLIENT library solution --- */

  // const authState = getAuthState();
  // const uploadLink = createUploadLink({
  //   uri: URLS['GRAPHQL'],
  //   headers: {
  //     ...(authState.accessToken != null
  //       ? { Authorization: `JWT ${authState.accessToken}` }
  //       : {})
  //     // 'Apollo-Require-Preflight': 'true'
  //   }
  //   // FormData: CustomFormData,
  //   // formDataAppendFile: customFormDataAppendFile = formDataAppendFile,
  //   // fetchOptions,
  //   // credentials,
  //   // headers,
  // });

  // const client = new ApolloClient({
  //   ssrMode: typeof window === 'undefined',
  //   link: uploadLink,
  //   cache: new InMemoryCache({
  //     fragments: createFragmentRegistry(...FRAGMENTS)
  //   }),
  //   connectToDevTools: true
  // });

  /* --- an old/current main APOLLO library solution --- */

  // const httpLink = createHttpLink({
  //   uri: URLS['GRAPHQL']
  // });
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const authState = getAuthState();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...(authState.accessToken != null
          ? { Authorization: `JWT ${authState.accessToken}` }
          : {})
      }
    };
  });

  const uploadLink = createUploadLink({ uri: URLS['GRAPHQL'] });

  const client = new ApolloClient({
    connectToDevTools: true,
    link: from([authLink, uploadLink]),
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(...FRAGMENTS),
      typePolicies: {
        AesStateMapObject: {
          keyFields: ['identifier']
        },
        AesActionObject: {
          keyFields: ['templateId']
        },
        AesCheckpointObject: {
          keyFields: ['templateId']
        },
        DataCalculatorResponseObject: {
          keyFields: ['frontendId']
        }
      }
    })
  });

  return client;
}
